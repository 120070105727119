import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import './GameOver.css';
import { useGame } from './components/StoreContext';
// import { Message } from './Message';
import { TotalPacManDyingAnimationLength } from './model/pacManDyingPhase';

export const TOTAL_TIME_TO_GAME_OVER_MESSAGE = TotalPacManDyingAnimationLength;

export const GameOver: FC<{ className?: string }> = observer(
  ({ className }) => {
    const game = useGame();
    const { pacMan } = game;
    const gameOverMessageVisible =
      game.gameOver && pacMan.timeSinceDeath >= TOTAL_TIME_TO_GAME_OVER_MESSAGE;

    // refresh the page after 5 seconds
    if (gameOverMessageVisible) {
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }

    return gameOverMessageVisible ? (
      <div
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: 'red',
          display: 'flex',

          position: 'absolute',

          zIndex: 39949,
        }}
      >
        <img
          src="https://i.ibb.co/7zGK22s/Screen-Shot-2022-12-15-at-5-38-05-AM.png"
          alt="skull"
          style={{ height: '100%', width: '100%' }}
        />
      </div>
    ) : null;
  }
);
