import './pages/GamePage/components/GlobalStyles.css';
import { BrowserRouter } from 'react-router-dom';
import { GamePage } from './pages/GamePage/GamePage';

import { Store } from './pages/GamePage/components/model/Store';
import { StoreProvider } from './pages/GamePage/components/components/StoreContext';

import './stylesheet.css';

const App = ({ store = new Store(), Router = BrowserRouter }) => {
  return (
    <StoreProvider value={store}>
      <div className="main">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <GamePage />
        </div>
      </div>
    </StoreProvider>
  );
};

export default App;
