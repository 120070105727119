import { Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Board } from './components/components/Board';
// import { DebugView } from './components/DebugView';
import { ExtraLives } from './components/ExtraLives';
import { GameOver } from './components/GameOver';
import { GhostsGameView } from './components/GhostsView';
import { MazeView } from './components/MazeView';
import { PacManView } from './components/PacManView';
import { PillsView } from './components/PillsView';
import { Score } from './components/Score';
import { useStore } from './components/components/StoreContext';
import { useKeyboardActions } from './components/useKeyboardActions';
import { VSpace } from './components/components/Spacer';
import { useGameLoop } from './components/model/useGameLoop';
import * as tmPose from '@teachablemachine/pose';
import '../../stylesheet.css';
// import { TwitterTweetEmbed } from 'react-twitter-embed';
export const GamePage = observer(() => {
  const mobileWidth = window.innerWidth < 500 ? 200 : 480;

  const store = useStore();
  const { game } = store;
  const pacMan = game.pacMan;

  const [direction, setDirection] = useState('up');
  const [model, setModel] = useState(null);
  const [cameraGood, setCameraGood] = useState(false);
  const [message, setMessage] = useState('wk');
  const [start, setStart] = useState(false);

  const startPose = async () => {
    getCanvas();

    setMessage('hit');

    const camera = document.getElementById('hiddenCanvas');

    const ctx = camera.getContext('2d').getImageData(0, 0, 640, 480);

    const { posenetOutput } = await model.estimatePose(ctx, true);

    const prediction = await model.predict(posenetOutput);

    // prediction is an array of 4 different probabilities,
    // i want to set the highest one to a state

    const highest = prediction.reduce((prev, current) =>
      prev.probability > current.probability ? prev : current
    );

    if (highest.className === 'Class 4') {
      setDirection('left');
      console.log('left');
    }
    if (highest.className === 'Class 2') {
      setDirection('right');
      console.log('right');
    }
    if (highest.className === 'Class 1') {
      setDirection('up');
    }
    if (highest.className === 'Class 3') {
      setDirection('down');
    }

    startPose();
  };

  const startWebcam = async () => {
    const URL = 'https://teachablemachine.withgoogle.com/models/Z56EKwt29/';
    const modelURL = URL + 'model.json';
    const metadataURL = URL + 'metadata.json';
    const model = await tmPose.load(modelURL, metadataURL);

    setModel(model);

    const video = document.querySelector('video');
    const canvas = (window.canvas = document.querySelector('canvas'));

    canvas.width = 480;
    canvas.height = 360;

    function handleSuccess(stream) {
      window.stream = stream;
      video.srcObject = stream;
      setCameraGood(true);
    }

    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then(handleSuccess);
  };
  const getCanvas = () => {
    const video = document.querySelector('video');
    const canvas = (window.canvas = document.querySelector('canvas'));
    canvas.width = 480;
    canvas.height = 360;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
  };

  // useEffect(() => {
  //   startWebcam();
  // }, []);
  useEffect(() => {
    if (cameraGood === true) {
      startPose();
    }
  }, [cameraGood]);

  useEffect(() => {
    store.resetGame();
    return () => {
      store.game.gamePaused = true;
    };
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const setDirectionl = result => {
    if (result === 'left') {
      pacMan.nextDirection = 'LEFT';
    }
    if (result === 'right') {
      pacMan.nextDirection = 'RIGHT';
    }
    if (result === 'up') {
      pacMan.nextDirection = 'UP';
    }

    if (result === 'down') {
      pacMan.nextDirection = 'DOWN';
    }
  };

  useEffect(() => {
    setDirectionl(direction);
  }, [direction]);

  useGameLoop();
  useKeyboardActions(message);

  return (
    <Layout data-testid="GamePage">
      {cameraGood === true && start === true && (
        <>
          <h1 className="title2">pacman.lol</h1>

          <h2 style={{ color: 'white' }}>Currently Facing: {direction}</h2>

          <ScoreArea>
            <Row justify="center">
              <Score />
            </Row>
            <VSpace size="small" />
          </ScoreArea>
          <EmptyArea />
          <BoardArea>
            <Board>
              <MazeView />
              <PillsView />
              <PacManView />
              <GhostsGameView />
              <GameOver />
            </Board>
            <VSpace size="small" />
            <Row justify="center">
              <ExtraLives />
            </Row>
          </BoardArea>
        </>
      )}
      {start === false && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1 className="title">pacman.lol</h1>

          <img
            className="pacmanimg"
            src="https://i.ibb.co/wLd83yY/Screen-Shot-2022-12-15-at-9-48-13-AM.png"
            alt="pacman"
          />

          {/* two buttons, one ensure camera is on, second to play */}
          <div className="parent">
            <button
              onClick={startWebcam}
              disabled={cameraGood}
              className="butt"
            >
              {cameraGood ? 'Camera Enabled' : 'Enable Camera'}
            </button>

            <button
              onClick={() => setStart(true)}
              disabled={!cameraGood}
              className="butt"
            >
              Start Game
            </button>
          </div>
        </div>
      )}

      <video
        style={{
          paddingTop: 10,
          paddingRight: 5,
          borderRadius: 30,
          width: mobileWidth,
          position: 'absolute',
          top: 0,
          right: 0,
        }}
        playsInline
        autoPlay
      >
        <div style={{ display: 'none' }}>
          {/* {cameraGood && (} */}
          <canvas id="hiddenCanvas"></canvas>
        </div>
      </video>

      {/* <DebugArea>
        <DebugView />
      </DebugArea> */}
    </Layout>
  );
});

const Layout = styled.div`
  margin-left: 16px;
  margin-right: 16px;

  display: grid;

  @media (min-width: 1280px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`;

const ScoreArea = styled.div``;

const EmptyArea = styled.div``;

const BoardArea = styled.div``;

// const DebugArea = styled.div`
//   @media (max-width: 1280px) {
//     display: none;
//   }
// `;
